<template>
  <nav>
    <v-app-bar app color="primary" dark dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon v-if="drawer" large>mdi-chevron-left</v-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-icon class="mx-3 text-h3">mdi-truck-fast</v-icon>
        <span class="text-h5 font-weight-bold">出荷システム</span>
      </div>

      <v-spacer></v-spacer>
      <span class="text-h5 font-weight-bold">{{ this.$route.name }}</span>
      <v-spacer></v-spacer>

      <v-btn
        v-show="
          $route.path === '/shukkashoruisakusei' ||
          $route.path === '/seiyakuankenkakunin' ||
          $route.path === '/courierlimit'
        "
        small
        color="white"
        depressed
        class="primary--text mr-2"
        @click="showCourierRules"
        >クーリエルール<v-icon small>mdi-book-open-variant</v-icon></v-btn
      >
      <v-btn
        v-show="hasInstructionManual"
        small
        color="white"
        depressed
        class="primary--text mr-2"
        @click="setShowInstruction(true)"
        >マニュアル<v-icon small>mdi-book-open-variant</v-icon></v-btn
      >
      <v-btn
        v-if="user.referenceable_base?.length === 1"
        class="mr-2"
        style="color: white !important"
        outlined
        disabled
      >
        {{ user.referenceable_base[0].name }}
      </v-btn>
      <v-menu v-else-if="user.referenceable_base?.length > 1" offset-y class="d-inline-block">
        <template v-slot:activator="{ on, attrs }">
          <v-btn :loading="loading" v-bind="attrs" v-on="on" class="body-1 mr-2" router outlined>
            {{ selectedReferenceableBase }}
            <v-icon medium dark>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(base, i) in user.referenceable_base"
            :key="i"
            @click.stop="changeReferenceableBase(base.id)"
          >
            <v-checkbox v-model="base.ref_flag" :label="base.name" readonly />
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text :href="VUE_APP_ARMS_LOGIN_URL">
        <span class="mr-2">ARMSに戻る</span>
      </v-btn>
      <v-btn text @click="logout">
        <span class="mr-2">ログアウト</span>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app width="280">
      <v-list nav>
        <div class="d-flex justify-end">
          <v-switch
            v-model="$vuetify.theme.dark"
            class="my-0"
            prepend-icon="mdi-white-balance-sunny"
            append-icon="mdi-moon-waxing-crescent"
            dense
            @change="$cookies.set('dark', $vuetify.theme.dark, 60 * 60 * 24 * 30)"
          ></v-switch>
        </div>
        <v-list-item-group v-model="group" active-class="primary--text text--accent-2 justify-space-between">
          <v-list-item
            v-for="link in links"
            :key="link.name"
            :to="link.path"
            route
            class="py-0 pr-1"
            @click="mainNavbarClick(link.meta, link.path)"
          >
            <v-list-item-content style="flex-grow: 1.2">
              <v-list-item-title v-text="link.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="info[link.meta.name]" class="py-1">
              <v-list-item-subtitle>
                <div v-for="{ key, label, count } in info[link.meta.name]" :key="key">
                  <v-btn text small block elevation="0" class="px-1" @click.stop.prevent="click(link, key)">
                    <div class="d-flex" style="width: 100%">
                      <span class="flex-grow-1 text-left">{{ label }}</span>
                      <span>{{ count }}</span>
                    </div>
                  </v-btn>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    drawer: true,
    loading: true,
  }),
  methods: {
    ...mapActions({
      setShowInstruction: 'ui/setShowInstruction',
      setShowCourierRule: 'ui/setShowCourierRule',
      apiGetSeiyakuAnken: 'api/getSeiyakuAnken',
      apiGetShipmentConfirmPackings: 'api/getShipmentConfirmPackings',
      apiGetPackingsReadyForShipment: 'api/getPackingsReadyForShipment',
      apiGetDataShukkaHikiWatashiItemsActions: 'shukkaJunbiHikiWatashi/getDataShukkaHikiWatashiItemsActions',
    }),
    showCourierRules() {
      this.setShowCourierRule(true);
      this.setShowInstruction(true);
    },

    logout() {
      this.$cookies.remove('jwt');
      window.location.href = process.env.VUE_APP_LOGIN_URL;
    },
    mainNavbarClick(meta, path) {
      if (meta.storeModule) {
        this.$store.dispatch('common/setSearchConditionsInitialValues');
        const handlers = {
          '/seiyakuankenkakunin': this.apiGetSeiyakuAnken,
          '/shukkaankenkakutei': this.apiGetShipmentConfirmPackings,
          '/shukkashoruisakusei': this.apiGetPackingsReadyForShipment,
          '/shukkajunbihikiwatashi': this.apiGetDataShukkaHikiWatashiItemsActions,
        };
        handlers[path](this.commonSearchConditions[meta.storeModule]);
      }
    },
    click(link, key) {
      const { path, name, meta } = link;
      this.$store.dispatch('common/resetSearchConditions');
      const searchConditions = {
        ...this.commonSearchConditions[meta.storeModule],
        progress: this.nextStatus(path, key),
        baseIdList: this.user.referenceable_base.filter((x) => x.ref_flag).map((x) => x.id),
      };
      const handlers = {
        '/seiyakuankenkakunin': this.apiGetSeiyakuAnken,
        '/shukkaankenkakutei': this.apiGetShipmentConfirmPackings,
        '/shukkashoruisakusei': this.apiGetPackingsReadyForShipment,
        '/shukkajunbihikiwatashi': this.apiGetDataShukkaHikiWatashiItemsActions,
      };
      handlers[path](searchConditions);
      path !== this.$route.path ? this.$router.push({ name }) : void 0;
      this.$store.dispatch('common/setSearchConditions', {
        searchConditions: searchConditions,
        storeName: meta.storeModule,
      });
    },
    nextStatus(path, key) {
      switch (path) {
        case '/seiyakuankenkakunin': // 成約案件確認
          switch (key) {
            case 'beforeShipmentCount':
              return this.progressStatus.seiyakuAnken[0].value;
            case 'paidOrUnpaidShippingCount':
              return this.progressStatus.seiyakuAnken[1].value;
            case 'unpaidCount':
              return this.progressStatus.seiyakuAnken[2].value;
            default:
              return;
          }
        case '/shukkaankenkakutei': // 出荷案件確定
          switch (key) {
            case 'shipmentConfirmCount':
              return this.progressStatus.shukkaAnkenKakutei[0].value;
            case 'beforeScheduledCount':
              return this.progressStatus.shukkaAnkenKakutei[1].value;
            case 'afterScheduledCount':
              return this.progressStatus.shukkaAnkenKakutei[2].value;
            case 'shipmentPendingCount':
              return this.progressStatus.shukkaAnkenKakutei[3].value;
            default:
              return;
          }
        case '/shukkashoruisakusei': // 出荷書類作成
          switch (key) {
            case 'creatingDocsTotal':
              return this.progressStatus.shukkaShoruiSakusei[0].value;
            case 'creatingDocsCount':
              return this.progressStatus.shukkaShoruiSakusei[1].value;
            case 'createdDocsCount':
              return this.progressStatus.shukkaShoruiSakusei[2].value;
            default:
              return;
          }
        case '/shukkajunbihikiwatashi': // 出荷準備-引渡
          switch (key) {
            case 'preparedShipmentCount':
              return this.progressStatus.shukkaJunbiHikiWatashi[0].value;
            default:
              return;
          }
        default:
          return;
      }
    },
    async changeReferenceableBase(id) {
      if (!this.loading) {
        // 現在の選択状態を取得
        const currentSelection = this.user.referenceable_base.filter((base) => base.ref_flag).map((base) => base.id);

        // 新しい選択状態を計算
        const nextSelection = currentSelection.includes(id)
          ? currentSelection.filter((baseId) => baseId !== id)
          : [...currentSelection, id];

        // バリデーション
        if (nextSelection.length === 0) return;

        // 選択状態を更新
        this.user.referenceable_base.forEach((base) => {
          base.ref_flag = nextSelection.includes(base.id);
        });

        // 状態更新
        this.$store.dispatch(
          'base/setCurrentBases',
          this.user.referenceable_base.filter((base) => nextSelection.includes(base.id)),
        );

        // サマリ取得
        this.loading = true;
        await this.$store.dispatch('api/getSummary', nextSelection, { root: true });
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
      statusItems: 'defined/statusItems',
      pathName: 'defined/pathNames',
      commonSearchConditions: 'common/searchConditions',
      baseList: 'base/baseList',
      user: 'defined/user',
      summary: 'summary/items',
    }),
    progressStatus() {
      return this.$store.state.defined.progressStatus;
    },
    VUE_APP_ARMS_LOGIN_URL: () => process.env.VUE_APP_ARMS_LOGIN_URL,
    links() {
      return this.$router.options.routes.filter((r) => r.name);
    },
    info() {
      const { items } = this.$store.state.summary;
      if (!items) return {};
      const {
        //成約案件確認-総件数(出荷済みになっていない全件)
        beforeShipmentCount,
        //成約案件確認-作業中(入金済みまたは未入金出荷分)
        paidOrUnpaidShippingCount,
        //成約案件確認-未入金(未入金案件一覧画面と同じ条件。未入金出荷分も表示する。ステータスは関係ない。)
        unpaidCount,
        //出荷案件確定-総件数(入金済みまたは未入金出荷分)★成約案件確認-作業中と同じ
        shipmentConfirmCount,
        //出荷案件確定-作業中（出荷予定確定前）
        beforeScheduledCount,
        //出荷案件確定-作業済（★出荷書類作成-総件数と同じ意味合いでは。）
        afterScheduledCount,
        //出荷案件確定-出荷保留（出荷保留中のもの）
        shipmentPendingCount,
        //出荷書類作成-総件数
        creatingDocsOrPreparedShipmentCount: creatingDocsTotal,
        //書類作成-作業中
        creatingDocsCount,
        //出荷書類作成-作業済（★出荷準備-引渡し-作業中と同じ）
        createdDocsCount,
        //出荷準備-引渡し-作業中（出荷書類作成後から引渡前のもの）
        preparedShipmentCount,
      } = items;
      return {
        SeiyakuAnkenKakunin: [
          {
            key: 'beforeShipmentCount',
            label: `総件数`,
            count: `${beforeShipmentCount}件`,
          },
          {
            key: 'paidOrUnpaidShippingCount',
            label: `作業中`,
            count: `${paidOrUnpaidShippingCount}件`,
          },
          { key: 'unpaidCount', label: `未入金`, count: `${unpaidCount}件` },
        ],
        ShukkaAnkenKakutei: [
          {
            key: 'shipmentConfirmCount',
            label: `総件数`,
            count: `${shipmentConfirmCount}件`,
          },
          {
            key: 'beforeScheduledCount',
            label: `作業中`,
            count: `${beforeScheduledCount}件`,
          },
          {
            key: 'afterScheduledCount',
            label: `作業済`,
            count: `${afterScheduledCount}件`,
          },
          {
            key: 'shipmentPendingCount',
            label: `出荷保留`,
            count: `${shipmentPendingCount}件`,
          },
        ],
        ShukkaShoruiSakusei: [
          {
            key: 'creatingDocsTotal',
            label: `総件数`,
            count: `${creatingDocsTotal}件`,
          },
          {
            key: 'creatingDocsCount',
            label: `作業中`,
            count: `${creatingDocsCount}件`,
          },
          {
            key: 'createdDocsCount',
            label: `作業済`,
            count: `${createdDocsCount}件`,
          },
        ],
        ShukkaJunbiHikiwatashi: [
          {
            key: 'preparedShipmentCount',
            label: `作業中`,
            count: `${preparedShipmentCount}件`,
          },
        ],
      };
    },
    hasInstructionManual() {
      const pathsWithInstructionManuals = [
        this.pathName.KONPOMEISAI,
        this.pathName.SEIYAKU_ANKEN_KAKUNIN,
        this.pathName.YUUSENJUNI_SAKUSEI,
        this.pathName.SHUKKA_ANKEN_KAKUTEI,
        this.pathName.SHUKKA_SHORUI_SAKUSEI,
        this.pathName.SHUKKA_JUNBI_HIKIWATASHI,
        this.pathName.SHUKKA_ZUMI_ANKEN,
      ];
      return pathsWithInstructionManuals.find((a) => a === this.$route.name);
    },
    selectedReferenceableBase() {
      const filter = this.user.referenceable_base.filter((x) => x.ref_flag);
      if (filter.length === this.user.referenceable_base.length) {
        return '全拠点';
      } else {
        return filter.map((x) => x.name).join('、');
      }
    },
  },
  watch: {
    user: {
      handler: function (user) {
        this.$store.dispatch('base/setBaseList', user.referenceable_base);
      },
    },
    summary: {
      handler: function () {
        this.loading = false;
      },
    },
  },
};
</script>
